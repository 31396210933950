<template>
  <v-container fluid>
    <Dashboard
      v-if="isLoaded && dashboardData.is_active"
      :company-id="companyId"
      :dashboard-id="dashboardId"
      :dashboard-data="dashboardData"
    />
    <v-alert v-else-if="isLoaded && !dashboardData.is_active" border="bottom" colored-border type="info" elevation="2">
      <span>Дашборд тимчасово відключено</span>
    </v-alert>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DashboardMain',

  components: {
    Dashboard: () => import('@/components/common/Dashboard'),
  },

  data: () => ({
    isLoaded: false,
  }),

  computed: {
    ...mapState('dashboard', ['dashboardData']),

    companyId() {
      return this.$route.params.id
    },
    dashboardId() {
      return this.$route.params.dashboardId
    },
  },

  watch: {
    '$route.path': {
      handler() {
        this.initialize()
      },
      immediate: true,
    },
  },

  created() {},

  methods: {
    ...mapActions('dashboard', ['loadDashboardData']),

    async initialize() {
      this.isLoaded = false
      const payload = {
        companyId: this.companyId,
        dashboardId: this.dashboardId,
      }
      await this.loadDashboardData(payload)
      this.isLoaded = true
    },
  },
}
</script>
